<template>
  <div>
    <app-filter :filter-config="gamesFilterConfig"
                @filtersChanged="fetchData" />
    <div class="popular-games-wrapper fluid-container">
      <div v-if="$route.params.companyId && gamesListLength"
           class="popular-games-content">
        <div class="popular-games-headline">
          <h1 class="popular-games-headline-text">
            {{ 'popular_page_title'|translate }}
          </h1>
        </div>
        <div class="popular-games">
          <table class="popular-games-table">
            <Draggable v-model="draggablePopularGames"
                       v-bind="dragOptions"
                       class="popular-games-table-container">
              <tr v-for="game in draggablePopularGames"
                  :key="game.id"
                  class="popular-games-row">
                <td class="popular-game-image-placeholder"
                    :style="{ backgroundImage: `url('${game.imageUrl}')` }" />
                <td class="popular-game-check">
                  <span class="g-icon"
                        :class="[game.popular ? 'g-icon-checkbox-c' : 'g-icon-checkbox']"
                        @click="updateGameIsPopular(game)" />
                </td>
                <td class="popular-game-title">
                  {{ game.title }}
                </td>
                <td class="popular-game-drag">
                  <span v-if="!dragOptions.disabled"
                        class="g-icon g-icon-grab" />
                </td>
              </tr>
            </Draggable>
            <tr v-for="game in nonPopularGames"
                :key="game.id"
                class="popular-games-row">
              <td class="popular-game-image-placeholder"
                  :style="{ backgroundImage: `url('${game.imageUrl}')` }" />
              <td class="popular-game-check">
                <span class="g-icon"
                      :class="[game.popular ? 'g-icon-checkbox-c' : 'g-icon-checkbox']"
                      @click="updateGameIsPopular(game)" />
              </td>
              <td class="popular-game-title">
                {{ game.title }}
              </td>
              <td class="popular-game-drag" />
            </tr>
          </table>
        </div>
      </div>
      <div v-if="!$route.params.companyId || !gamesListLength"
           class="no-data-placeholder">
        <h1 v-if="$route.params.companyId && !gamesListLength"
            class="no-data-message">
          <label>
            {{ 'all_games_no_games'|translate }}
          </label>
        </h1>
        <h1 v-if="!$route.params.companyId"
            class="no-data-message">
          <label>
            {{ 'all_games_select_company'|translate }}
          </label>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapState, mapGetters, mapActions } from 'vuex';
import AppFilter from '../components/AppFilter';

export default {
  name: 'Popular',
  components: {
    Draggable,
    AppFilter,
  },
  data() {
    return {
      reorderedPopularGames: null,
      dragOptions: {
        forceFallback: true,
        fallbackTolerance: 3,
        scrollSensitivity: 200,
        scrollSpeed: 20,
        disabled: false,
        handle: '.popular-game-drag',
      },
    };
  },
  computed: {
    ...mapState(['filterParams']),
    ...mapGetters(['gamesFilterConfig', 'popularGames', 'nonPopularGames', 'isReorderingAvailable']),

    draggablePopularGames: {
      get() {
        return this.reorderedPopularGames || this.popularGames;
      },
      async set(value) {
        this.toggleLoader(true);

        const reorderedPopularGamesIds = value.map((game) => game.id);
        await this.updatePopularGamesOrder(reorderedPopularGamesIds);
        this.reorderedPopularGames = value;

        this.toggleLoader(false);
      },
    },
    gamesListLength() {
      return this.nonPopularGames.length + this.popularGames.length;
    },
  },
  updated() {
    this.dragOptions.disabled = !this.isReorderingAvailable;
  },
  created() {
    window.addEventListener('visibilitychange', this.visibilityHandler);
  },
  beforeDestroy() {
    window.removeEventListener('visibilitychange', this.visibilityHandler);
  },
  methods: {
    ...mapActions([
      'toggleLoader',
      'getCasinoGames',
      'updatePopularGamesOrder',
      'updateGamePopularStatus',
    ]),
    async fetchData(params = this.filterParams) {
      if (!params.companyId) return;

      this.toggleLoader(true);
      await this.getCasinoGames(params);
      this.toggleLoader(false);
    },
    async updateGameIsPopular(game) {
      await this.updateGamePopularStatus({ gameId: game.id, isPopular: !game.popular });
      this.reorderedPopularGames = null;
    },
    visibilityHandler(e) {
      const element = e.target;
      if (element.visibilityState === 'hidden') return;

      this.fetchData();
    },
  },

};
</script>

<style lang="scss" scoped>

.popular-games-wrapper {
  position: relative;
  padding-top: 0.625em;
}

.popular-games-content {
  background: #fff;
  box-shadow: $shadow-outline;
  padding: 1.2rem;
}

.popular-games-headline-text {
  color: $font-primary-1;
  font-size: $fs-500;
}

.popular-games {
  margin-top: 1em;
}

.popular-games-table {
  display: block;

  & > * + * {
    margin-top: 0.625em;
  }

  &-container {
    & > .popular-games-row + .popular-games-row {
      margin-top: 0.625em;
    }
  }
}

.popular-games-row {
  display: flex;
  align-items: center;
  height: 3.5em;
  color: $font-primary-1;
  box-shadow: $shadow-outline;
  background-color: $bg-100;

  .popular-game-image-placeholder {
    flex-shrink: 0;
    width: 5em;
    height: 100%;
    background-color: $bg-300;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .popular-game-check {
    flex-shrink: 0;
    width: 3em;
    margin-top: 0.15em;
    text-align: center;
    cursor: pointer;
  }

  .popular-game-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 1rem;
  }

  .popular-game-drag {
    margin-left: auto;
    padding-right: 1em;
    color: $font-primary-3;
    cursor: move;
  }
}

.no-data-placeholder {
  padding: 2.4rem 0;
  text-align: center;

  .no-data-message {
    height: 5.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $fs-300;
    font-weight: bold;
    color: $font-primary-1;
    border: thin dashed $font-primary-3;
    border-radius: $br-sm;
  }
}
</style>
