<template>
  <div>
    <app-filter :filter-config="groupsFilterConfig"
                @filtersChanged="fetchData" />
    <div class="groups-wrapper fluid-container">
      <div v-if="$route.params.companyId"
           class="g-table-andromeda">
        <div class="group-row head">
          <div class="group-data">
            <label class="group-head-label">{{ 'groups_id' | translate }}</label>
          </div>
          <div class="group-data">
            <label class="group-head-label">{{ 'groups_title' | translate }}</label>
          </div>
          <div class="group-data">
            <label class="group-head-label">{{ 'groups_name' | translate }}</label>
          </div>
          <div class="group-data">
            <label class="group-head-label">{{ 'edit_modal_createdAt_label' | translate }}</label>
          </div>
          <div class="group-data">
            <label class="group-head-label">{{ 'edit_modal_updatedAt_label' | translate }}</label>
          </div>
          <div class="group-data">
            <label class="group-head-label">{{ 'groups_games' | translate }}</label>
          </div>
          <div class="group-data">
            <label class="group-head-label">{{ 'groups_subgroups' | translate }}</label>
          </div>
        </div>
        <draggable v-model="casinoGamesGroups"
                   v-bind="{ disabled: isMobile }"
                   class="group-rows">
          <div v-for="group in casinoGamesGroups"
               :key="group.id"
               class="group-row">
            <div class="group-data">
              <label class="group-label-head">{{ 'groups_id' | translate }}</label>
              <label class="group-label">{{ group.id }}</label>
            </div>
            <div class="group-data">
              <label class="group-label-head">{{ 'groups_title' | translate }}</label>
              <label class="group-label">{{ group.title }}</label>
            </div>
            <div class="group-data">
              <label class="group-label-head">{{ 'groups_name' | translate }}</label>
              <label class="group-label">{{ group.name }}</label>
            </div>
            <div class="group-data">
              <label class="group-label-head">{{ 'edit_modal_createdAt_label' | translate }}</label>
              <label class="group-label">{{ group.createdAt }}</label>
            </div>
            <div class="group-data">
              <label class="group-label-head">{{ 'edit_modal_updatedAt_label' | translate }}</label>
              <label class="group-label">{{ group.updatedAt }}</label>
            </div>
            <div class="group-data">
              <label class="group-label-head">{{ 'Games' | translate }}</label>
              <label class="group-label">{{ group.gamesCount }}</label>
            </div>
            <div class="group-data">
              <label class="group-label-head">{{ 'Subgroups' | translate }}</label>
              <label class="group-label">{{ group.subgroupsCount }}</label>
            </div>
            <div class="group-data-icons">
              <div class="group-data">
                <label class="group-label-head">{{ 'groups_active' | translate }}</label>
                <label class="group-label"
                       :class="group.active ? 'group-label-green' : 'group-label-orange'">
                  <span class="g-icon"
                        :class="group.active ? 'g-icon-check' : 'g-icon-close'" />
                </label>
              </div>
              <div class="group-data">
                <label class="group-label-head">{{ 'groups_edit' | translate }}</label>
                <label class="group-label">
                  <span class="g-icon g-icon-edit"
                        @click="openModal($event, group)" />
                </label>
              </div>
              <div class="group-data grab-icon-dt">
                <label v-if="!isMobile"
                       class="group-label g-icon g-icon-grab" />
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <button v-if="$route.params.companyId"
              class="g-button g-button-pluto"
              @click="openModal">
        <i class="pluto-icon g-icon g-icon-plus" />
      </button>
      <div v-if="!$route.params.companyId"
           class="no-data">
        <h1 class="no-data-message">
          <label>
            {{ 'all_games_select_company' | translate }}
          </label>
        </h1>
      </div>
      <div v-if="$route.params.companyId && !casinoGamesGroups.length"
           class="no-data">
        <h1 class="no-data-message">
          <label>
            {{ 'groups_no_groups' | translate }}
          </label>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState, mapGetters, mapActions } from 'vuex';
import AppFilter from '../components/AppFilter';

export default {
  name: 'Groups',
  components: {
    draggable,
    AppFilter,
  },
  computed: {
    ...mapState({
      tableConfig: (state) => state.groups.tableConfig,
      filterParams: (state) => state.filterParams,
    }),
    ...mapGetters(['groupsFilterConfig']),
    casinoGamesGroups: {
      get() {
        return this.$store.getters.casinoGamesGroups;
      },
      set(value) {
        this.toggleLoader(true);

        this.$store.dispatch('updateGroupsOrder', {
          companyId: this.$route.params.companyId,
          order: value.map((group) => group.id),
        }).finally(() => this.toggleLoader(false));
      },
    },
    isMobile() {
      return window.screen.width < 1024;
    },
  },
  methods: {
    ...mapActions([
      'toggleLoader',
    ]),
    async fetchData(params = this.filterParams) {
      if (!params.companyId) return;
      this.toggleLoader(true);

      try {
        await this.$store.dispatch('getCasinoGamesGroups', params);
        await this.$store.dispatch('getAllCompanyGames', params);
      } finally {
        this.toggleLoader(false);
      }
    },
    openModal(ev, group = {}) {
      const submitAction = !Object.entries(group).length
        ? 'createCasinoGamesGroup'
        : 'updateCasinoGamesGroup';
      this.$modal.show('group-modal', {
        groupId: group.id,
        submitAction,
      });
    },
  },

};
</script>

<style lang="scss" scoped>
@use "@/styles/components/_table";
</style>
